import * as React from 'react'

import PropTypes from 'prop-types'
import { wineType } from '@/model/wine'
import { useRecoilValue } from 'recoil'

import { SwiperSlide } from 'swiper/react'
import { GridContainer } from '@/components/Grid'
import WinesSliderItem from '@/components/WinesSlider/WinesSliderItem'
import CircularLabelButton from '@/components/CircularLabelButton'
import Arrows from '@/components/Block/Sliders/atoms/Arrows'

import isExternalLink from '@/utils/isExternalLink'
import { isMobileState } from '@/recoil/layout'

import {
  Container,
  Head,
  HeadWrapper,
  Title,
  Subtitle,
  Text,
  Wrapper,
  SwiperContent,
  ActionWrapper
} from './style'

const WinesSlider = ({ id, linkTitle, subtitle = '', text = '', title = '', items, label = 'Our Wines', to = '/our-wines' }) => {
  const isMobile = useRecoilValue(isMobileState)
  return (
    <Container>
      <GridContainer>
        {(title.length > 0) && (
          <Head>
            <HeadWrapper>
              { (subtitle && subtitle.length > 0) && (<Subtitle>{subtitle}</Subtitle>) }
              <Title>{title}</Title>
              { (text && text.length > 0) && (<Text>{text}</Text>) }
            </HeadWrapper>
            <CircularLabelButton
              id={ id || 'textcercle' }
              linkTitle={ linkTitle }
              label={ label }
              nbLabels={ isMobile ? 3 : 5 }
              to={ to }
              target={ isExternalLink(to) ? '_blank' : '_self' }
            />
          </Head>
        )}
        <Wrapper>
          <SwiperContent slidesPerView={ isMobile ? 1.5 : 'auto' }>
            {items.map((item, i) => (
              <SwiperSlide key={ i }>
                <WinesSliderItem item={ item } />
              </SwiperSlide>
            ))}

            {!isMobile && (
              <ActionWrapper>
                <Arrows display='inverted' />
              </ActionWrapper>
            )}
          </SwiperContent>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

WinesSlider.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  label: PropTypes.string,
  to: PropTypes.string,
  items: PropTypes.arrayOf(wineType).isRequired
}

export default WinesSlider
